/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'circle-square': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 6a6 6 0 1112 0A6 6 0 010 6"/><path pid="1" d="M12.93 5h1.57a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-1.57a7 7 0 01-1-.22v1.79A1.5 1.5 0 005.5 16h9a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 4h-1.79q.145.486.22 1"/>',
    },
});
